
import VideoContext from "./VideoContext";
import AuthContext from "./AuthContext";
import React, { useContext, useState } from 'react';
import {Link} from "react-router-dom";
import headphones from "../images/headphones.jpg";
import { XMarkIcon } from '@heroicons/react/20/solid'


export default function VideotestLista() {

  console.log("this message implies that VideoList is rerender every time");

  const {VideosFetched, VideoAdded, setVideoAdded, Loading}=useContext(VideoContext);

  const { User } = useContext(AuthContext);
  const user = {
    name: User.name,
    email: User.email,
  }
  
  const prefixToRemove = user.email + "/";
    
  const [isDeleting, setisDeleting]=useState(false);

  console.log("mediaFiles in videoLista" + VideosFetched);   

  const handleEliminateVideo=(event)=>{    
    console.log(event);
    const file_name=`${event.replace(prefixToRemove, '')}`
    const isConfirmed = window.confirm(`Esta seguro de borrar '${file_name}' ?`);
    
    if (isConfirmed){
      
      setisDeleting(file_name);

      fetch("https://yqxdpx5bo8.execute-api.us-east-1.amazonaws.com/dev/delete",{                
      mode:"cors",
      method:'post',                                       
      body:JSON.stringify({
        "video_key":event        
      }),      
      })
      .then(response=> {
          console.log(response);
          
          setVideoAdded(event);//whenever a video is deleted, VideoAdded context change, thus in LayoutDashboard a refresh of videoFects will take in place,
          //console.log("VideoAdded"+ VideoAdded);
          setTimeout(function() {           
            setisDeleting(false);          
          }, 3000);   
         
          return response.json();
          
      })
    }

  }

  
  return (
    <div className="bg-white mx-auto max-w-9xl py-4 sm:px-6 lg:px-8" >
      <div className="mx-auto max-w-2xl px-4 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-6"> 
        
        {/* render loading while fetching data*/}
        { Loading ? (<h2 className='text-xs text-slate-500 font-bold col-span-4 animate-pulse leading-loose text-center border-spacing-2 sm:text-xl truncate sm:text-ellipsis'>Cargando...</h2>) : ""}

        {/* render video cards */}
        {
          VideosFetched ? (
            VideosFetched.map((file, index) => (
              <div className="relative" key={index}>              
                <Link
                  to={`/${file.name.replace(prefixToRemove, '')}`}
                  key={file.name.replace(prefixToRemove, '')}
                  state={{
                    email: user.email,
                    video_key: file.name.replace(prefixToRemove, ''),
                    video_url: file.presigned_url,
                    media_content: file.media_content,
                    media_language: file.media_language,
                    media_size:file.media_size,                    
                    media_lenght_min: `${(file.media_lenght_sec / 60 * 0.02).toFixed(0)}min`,
                    transcription_key: file.transcription_key,
                    transcription_url: file.presigned_url_transcription,
                    summary_url: file.presigned_url_summary
                  }}
                >                
                  <div key={index} className="group relative hover:cursor-pointer">
                    <div className="aspect-auto rounded-md bg-gray-200 group-hover:opacity-60">
                      <div className="relative mt-8 sm:aspect-auto md:aspect-auto sm:mb-0 lg:mt-1 lg:aspect-auto">
                        <div className="rounded-md border-1 overflow-hidden shadow-2xl">
                          {/* +"#t=5" stay the video in second 5 to show something as thumbnail, poster apply a default image*/}
                          {<video 
                          src={(file.presigned_url + "#t=5")} 
                          poster={headphones} 
                          className="aspect-video w-full"
                          />
                          }
                          
                          
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="mt-2 flex justify-between">
                        <div className="truncate">
                          <h3 className="text-sm font-medium text-gray-700 ">
                            <div>
                              <span aria-hidden="true" className="" />
                              {file.name.replace(prefixToRemove, '')}
                            </div>
                          </h3>
                          <p className="mt-1 text-xs text-gray-500">
                            {`${file.media_size} MB`}
                          </p>
                        </div>
                        <p className="pl-4 text-xs font-medium text-gray-900">
                          {`${(file.media_lenght_sec / 60 * 0.02).toFixed(0)}min`}
                        </p>
                        
                        
                      </div>
                    </div>
                    
                  </div>
                </Link>
                <div className="flex justify-end ">
                          <button
                              type="button"
                              className="top-1 z-50 absolute text-black border-black border-0 bg-white rounded-full  hover:text-white hover:bg-red-600"
                              onClick={() => handleEliminateVideo(file.name)}                             
                            >
                              <span className="sr-only">Close</span>
                              <XMarkIcon className="h-4 w-auto" aria-hidden="true" />
                            </button>
                        </div>
                </div>
              ))
          ) : (
            <h1 className='text-xs text-slate-500 font-bold col-span-4 leading-loose text-center border-spacing-2 sm:text-xl truncate sm:text-ellipsis'>No tienes archivos en la plataforma</h1>  
          )
        }

        </div>
      </div>
      <div className="flex justify-center font-semibold text-white bg-red-500 leading-7 animate-pulse">
        {isDeleting && (<p>{`Eliminando ${isDeleting}...`}</p>) } {/* Display a message when a video is being deleted */}
      </div>
    </div>
  )
}