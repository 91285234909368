import video1 from "../images/videos/pexels-rodnae-productions-5762542 (2160p).mp4";
import { Link } from "react-router-dom";

function Hero() {
    const callsToAction = [
        { name: 'Prueba Gratis', href: '/login'}
      ]
    return (
        <div>
            <div className="pb-10">
                <div className="mx-auto max-w-7xl sm:px-2 pt-28 lg:px-1 lg:mt-8 lg:pb-10">
                    <div className="sm:px-2 sm:content-center sm:mb-0 md:pt-2 lg:flex lg:gap-x-10 lg:px-20 lg:pt-2">                       
                        <div className="min-w-2/4 text-center lg:mx-0 lg:flex-auto lg:text-left">                            
                            <h2 className="text-2xl font-bold tracking-normal text-black sm:text-3xl lg:text-4xl">
                            Con Inteligencia Artificial, aumente su productividad
                            </h2>
                            <p className="mt-4 text-lg leading-6 text-gray-700">
                                No tiene que preocuparse por tomar notas. 
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <Link
                                    to={callsToAction[0].href}
                                    className="rounded-md bg-sky-600 text-white px-3.5 py-2.5 text-sm font-semibold
                                    hover:bg-green-500">
                                    Prueba Gratis
                                </Link>                                
                            </div>
                        </div>

                        <div className="mt-6 lg:mt-2 flex items-center justify-center">
                            <div className="rounded-md border-1 overflow-hidden shadow-2xl max-w-md sm:max-w-xl lg:max-w-5xl">                                
                                <video id="video" autoPlay muted loop src={video1} type="video/mp4"></video>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hero;
