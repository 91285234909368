
import andrea from "../images/people/3.jpg"
import mauricio from "../images/people/8.jpg"
import catalina from "../images/people/2.jpg"
import gustavo from "../images/people/5.jpg"

function Reviews() {
  return (
    <div className="mx-auto flex max-w-7xl items-center justify-between py-6 px-4 lg:px-1">        
      <ul>
        <li className="flex justify-between py-5">
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={andrea} alt="imagen persona"/>
            <div className="min-w-0 flex-auto">
              <p className="text-sm text-justify font-semibold leading-6 text-gray-900">"Anteriormente, solíamos pasar horas valiosas revisando grabaciones de audio y tomando notas, lo que consumía mucho tiempo y nos dejaba menos tiempo para enfocarnos en proyectos y tareas más importantes"</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Andrea Giraldo</p>
            </div>
          </div>          
        </li>

        <li className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={mauricio} alt="imagen persona"/>
            <div className="min-w-0 flex-auto">
              <p className="text-sm text-justify font-semibold leading-6 text-gray-900">"La transcripción y el resumen nos permiten revisar rápidamente los puntos clave de nuestras discusiones y asegurarnos de que no se pierda ninguna información valiosa"</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Mauricio Rojas</p>
            </div>
          </div>          
        </li>

        <li className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={catalina}alt=""/>
            <div className="min-w-0 flex-auto">
              <p className="text-sm text-justify font-semibold leading-6 text-gray-900">"Nos ha proporcionado datos más precisos y coherentes que, en última instancia, han resultado en la toma de decisiones más informadas y acertadas en nuestra empresa"</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Catalina Pérez</p>
            </div>
          </div>          
        </li>

        <li className="flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
            <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={gustavo} alt=""/>
            <div className="min-w-0 flex-auto">
              <p className="text-sm text-justify font-semibold leading-6 text-gray-900">"Lo que más aprecio es la facilidad de uso de la plataforma y su capacidad de integrarse con nuestras aplicaciones y sistemas existentes sin problemas. Incluso aquellos miembros del equipo que son menos expertos en tecnología han podido utilizar esta herramienta sin dificultades y han expresado su satisfacción por cómo ha facilitado sus vidas laborales"</p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">Gustavo Jimenez</p>
            </div>
          </div>         
        </li>      
      </ul>
    </div>
  );
}

export default Reviews;
