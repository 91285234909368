import logo from "../images/logo.svg";
import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import {
  DocumentChartBarIcon,
  DocumentTextIcon,
  MicrophoneIcon,  
  ChatBubbleLeftEllipsisIcon,  
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline';

import { ChevronDownIcon, SunIcon } from '@heroicons/react/20/solid';
import { Link } from "react-router-dom";

const products = [
  { name: 'Actas de reuniones', description: 'Extraiga puntos desarrolados y compromisos pactados', href: '/casos-de-uso-actas', icon: DocumentChartBarIcon },
  { name: 'Podcast y Videos', description: 'Obtenga informacion en texto e imagenes de contenidos de terceros', href: '/casos-de-uso-podcastvideos', icon: MicrophoneIcon },
  { name: 'Documentación de capacitaciones', description: 'Registra lo expuesto', href: '/casos-de-uso-documentacioncapacitaciones', icon: DocumentTextIcon },  
  { name: 'Creación de contenido', description: 'Automatiza la creacion de contenido', href: '/casos-de-uso-creaciondecontenido', icon: ChatBubbleLeftEllipsisIcon },
]
const callsToAction = [
  { name: 'Prueba Gratis', href: '/login', icon: SunIcon }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function HeaderLanding() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);  
    
    return (
        <header className="bg-white border-b-2 fixed top-0 left-0 right-0 z-50">
            <nav className="mx-auto flex max-w-7xl items-center justify-between py-6 px-4 lg:px-0" aria-label="Global">
                <div className="flex lg:flex-1">                    
                        <Link to="/" className="-m-1.5 p-1.5" onClick= {() => window.scrollTo(0, 0)}>
                            <div className="flex">
                                <img className="h-8 w-auto" src={logo} alt="logo"/>
                                <span className="mx-1.5 text-xl font-bold">Transcribe-AI</span>
                            </div>
                        </Link>
                        
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">abrir menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>

                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <Popover className="relative">
                        <Popover.Button className="flex items-center gap-x-1 text-xl font-semibold leading-6 text-gray-900">
                        Casos de uso
                        <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </Popover.Button>

                        <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        >
                        <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                            <div className="p-4">
                                {products.map((item) => (
                                    <div
                                    key={item.name}
                                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-md leading-6 hover:bg-gray-100"
                                    >
                                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg group-hover:bg-gray-100">
                                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                    </div>
                                    <div className="flex-auto">
                                        <Link to={item.href} className="block font-semibold text-gray-900" onClick= {() => window.scrollTo(0, 0)}>
                                        {item.name}
                                        <span className="absolute inset-0" />
                                        </Link>
                                        <p className="mt-1 text-gray-600">{item.description}</p>
                                    </div>
                                    </div>
                                ))}
                            </div>
                            <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-blue-400 text-white hover:bg-green-500">
                                {callsToAction.map((item) => (
                                    <Link
                                    key={item.name}
                                    to={item.href}
                                    className="flex items-center justify-left gap-x-2.5 p-3 text-md font-semibold leading-6"
                                    onClick= {() => window.scrollTo(0, 0)}
                                    >
                                    <item.icon className="h-6 w-6 flex-none" aria-hidden="true" />
                                    {item.name}
                                    </Link>
                                ))}
                            </div>
                        </Popover.Panel>
                        </Transition>
                    </Popover>
                               
                    <Link to="/seguridad" className="text-xl font-semibold leading-6 text-gray-900" onClick= {() => window.scrollTo(0, 0)}>
                        Seguridad
                    </Link>
                </Popover.Group>
                
                <div className="hidden lg:flex lg:flex-1 lg:justify-end hover:cursor-pointer">
                    <div className="bg-sky-600 rounded-md p-2 pl-3 text-white hover:bg-green-500">
                        <Link to={callsToAction[0].href} className="text-md font-semibold leading-6 " onClick= {() => window.scrollTo(0, 0)}>
                            Prueba Gratis <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </div>
            </nav>
            
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
                <Link to="/" className="-m-1.5 p-1.5" onClick= {() => window.scrollTo(0, 0)}>
                <span className="sr-only">Transcribe-Ai</span>
                <img
                    className="h-8 w-auto"
                    src={logo}
                    alt="logo"
                />
                </Link>
                <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
                >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                    <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                        <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                            Casos de uso
                            <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                            {[...products].map((item) => (
                            <Link
                                key={item.name}
                                as="a"
                                to={item.href}
                                className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                onClick= {() => window.scrollTo(0, 0)}
                            >
                                {item.name}
                            </Link>
                            ))}
                        </Disclosure.Panel>
                        </>
                    )}
                    </Disclosure>                                
                    <Link
                    to="/seguridad"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick= {() => window.scrollTo(0, 0)}
                    >
                    Seguridad
                    </Link>
                </div>
                <div className="py-6">
                    <Link
                    to={callsToAction[0].href}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    onClick= {() => window.scrollTo(0, 0)}
                    >
                    Log in
                    </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}