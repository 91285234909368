import AuthContext from "./AuthContext";
import VideoContext from "./VideoContext";
import { useState, useEffect, useContext } from "react";
import Dropdown from './Dropdown';
import { CloudArrowUpIcon, XCircleIcon} from '@heroicons/react/24/solid'
import axios from 'axios';
import {useNavigate } from "react-router-dom";


// function to upload video by selecting or dragging from user pc

export default function Upload() {
 

  const[FileToUpload,setFileToUpload]=useState(null);
  const[progress,setProgress]=useState({started: false, pc: 0});
  const[msg, setMsg]=useState(null);
  
  const navigate = useNavigate();

  const { User } = useContext(AuthContext);
  const user = {
    name: User.name,
    email: User.email, };    

  const {setVideoAdded}=useContext(VideoContext);

  const [Specs,setSpecs]=useState({email:"", name:"", size:"", type:"", content:"", language:""})
  const [isFileHidden, setIsFileHidden] = useState(true);
  const[buttonHidden, setButtonHidden]=useState(true);
  const size_cap=1000 //define the size limit of the input file in MB
  
  
  //capture details of File selected by the user
  const handleFileChange = (event) => {    
    event.preventDefault();
    console.log(event.target.files[0]);
    setFileToUpload(event.target.files[0]);

    //Name adjust due to spaces, symbols and accents
    const name=event.target.files[0].name;    
    let name_adjusted = name.replace(/\s+/g, '_'); // First, we replace the spaces with underscores    
    name_adjusted = name_adjusted.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Then, we normalize the accent marks by separating them from the letters    
    name_adjusted = name_adjusted.replace(/[^a-zA-Z0-9_.]/g, ''); // finally we remove symbols except periods (.) and underscores (_) while retaining letters, numbers and periods.
    
    const specs=({email:user.email, name: name_adjusted, size: Math.round((event.target.files[0].size/1024)/1024), type:event.target.files[0].type});
    setSpecs(specs);
    //console.log(specs);
    if (specs.size > size_cap) {
      alert("Error: El archivo debe ser menor a " + size_cap +" MB");
      return;
    }
    if (!specs.type.startsWith("audio/") && !specs.type.startsWith("video/")){
      alert("Error: El archivo debe ser en un formato de video o audio");
      return;
    }  
    else
      setIsFileHidden(false);
      setSpecs({email:specs.email, name: specs.name, size: specs.size, type:specs.type});
      //console.log(File);    
  }

  //capture file content selection made by the user
  const meessageContent="Indique informacion que contiene el archivo"
    
  const optionsContent = [
    { label: 'Reunion'},
    { label: 'Entrevista'},
    { label: 'Podcast/video'},
    { label: 'Capacitación'},
  ];
  const [selectionContent, setSelectionContent] = useState(null);
  const handleSelectContent = (option) => {
    //console.log(option);
    setSelectionContent(option.label);   
    
  }
  //Use effect is to update the value of File when selection is updated
  useEffect(() => {
    setSpecs(prevSpecs => ({
      ...prevSpecs,
      content: selectionContent
    }));
  }, [selectionContent]);


  //capture file language selection made by the user
  const meessageLanguage="Indique el idioma del contenido del archivo"
    
  const optionsLanguage = [
    { label: 'es', src:'http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg', alt:"Spanish flag"},  
    { label: 'pt',src:'http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg', alt:"Brazilian flag"},   
    { label: 'en', src:'http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg', alt:"US flag"}, 
  ];
  const [selectionLanguage, setSelectionLanguage] = useState(null);  
  const handleSelectLanguage = (option) => {
    console.log(option);
    setSelectionLanguage(option.label);    
    setButtonHidden(false);
    
  }
  //Use effect is to update the value of File language when selection is updated
  useEffect(() => {
    setSpecs(prevSpecs => ({
      ...prevSpecs,
      language: selectionLanguage
    }));
  }, [selectionLanguage]);



  const handlerFormSubmit= (event)=>{
    event.preventDefault(); 
    console.log(Specs);
    setIsFileHidden(true);
    setButtonHidden(true);    

    console.log(FileToUpload);
    console.log(Specs);

    setMsg("Subiendo archivo....");
    setProgress(prevState=>{
      return {...prevState, started:true}
    })     

    //first fetch to get the presign url
    
    fetch("https://yqxdpx5bo8.execute-api.us-east-1.amazonaws.com/dev",{                
      mode:"cors",
      method:'post',                                       
      body:JSON.stringify(Specs),      
    })
    .then(response=> {
        //console.log(response);
        return response.json();
    })
    .then(data => {
      console.log("data to upload the file in S3:");      
      // url:
      console.log(data);
      const parsedBody = JSON.parse(data.body);      
      const url_upload_file=parsedBody.url; // This is your S3 presigned URL
      console.log("url to upload is: " + url_upload_file);
      const formFields = parsedBody.fields; // Additional fields for the S3 form upload
      
      //start second fetch to post the file in S3
      console.log("start second fetch to post the file in S3");
      
      const formData = new FormData();
      // Append the form fields to the form data
      Object.keys(formFields).forEach((key) => {
        formData.append(key, formFields[key]);
      });     
      formData.append('file', FileToUpload);  

        
      
      axios.post(url_upload_file, formData,{
        
        onUploadProgress: (progressEvent)=>{setProgress(prevState=>{
          return{...prevState, pc: progressEvent.progress*100}
        })},
        headers:{"Custom-Header":"value",},
      })
      .then(res=>{
        setMsg("El archivo se ha subido correctamente!" );  
        setTimeout(function() {           
          setProgress(prevState=>{
            return {...prevState, started:false};
          });
          navigate("/dashboard");
        }, 3000);        
                
        
        setVideoAdded(Specs.name); //whenever a video is added, VideoAdded context change, thus in LayoutDashboard a refresh of videoFects will take in place,
        console.log("setVideoAdded: "+ setVideoAdded);
        
        
      })
      .catch(err=>{      
        setMsg("No se pudo subir el archvivo, intente nuevamente");    
        setTimeout(function() {  
          setProgress(prevState=>{
            return {...prevState, started:false};
          })        
        }, 10000);

               
        console.log(err);
      });
      
    })
    setSpecs({email:"", name:"", size:"", type:"", content:"", language:""});
    setSelectionContent("");    
    setSelectionLanguage("");    
  };

  const handlerFormCancel= (event)=>{    
    setSpecs({email:"", name:"", size:"", type:"", content:"", language:""});
    setIsFileHidden(true);    
    setButtonHidden(true);
    setSelectionContent("");    
    setSelectionLanguage(""); 
    
  };

  const onDrop = (event) => {
  event.preventDefault();
  event.stopPropagation(); // Just to be safe, stop event propagation

  const files = event.dataTransfer.files;

  if (files.length > 0) {
    const file = files[0];
    console.log("Dropped file: ", file);

    if (file.size > size_cap * 1024 * 1024) {
      alert(`Error: El archivo debe ser menor a ${size_cap} MB`);
      return;
    }

    if (!file.type.startsWith("audio/") && !file.type.startsWith("video/")) {
      alert("Error: El archivo debe ser en un formato de video o audio");
      return;
    }

    const nameAdjusted = file.name
      .replace(/\s+/g, '_')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9_.]/g, '');
    
    const newSpecs = {
      email: user.email,
      name: nameAdjusted,
      size: Math.round((file.size / 1024) / 1024),
      type: file.type
    };
    
    setSpecs(newSpecs);
    setFileToUpload(file);
    setIsFileHidden(false);

    // The rest of your state updates as needed
  }
};
  
  const onDragOver = (event) => {    
    event.preventDefault(); 
    //console.log(event);
    
  }
  
  return (
    <form className="pb-10">
      <div className=""  onDragOver={onDragOver} onDrop={onDrop} >
        <div className="mx-auto max-w-2xl px-4 py-1 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
          <div className="mt-6 grid grid-cols-1 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">        
            <div className="col-span-full">
              <label className="block text-sm font-medium text-gray-900">
                Seleccione archivo en sus documentos para Analisis
              </label>            
                <div className="mt-2 justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-2 text-center">
                  <div className="text-center">                  
                    <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-400"
                      >
                        <span>Cargar archivo</span>
                        <input id="file-upload" name="file-upload" type="file" accept="audio/*,video/*" className="sr-only"  onChange={handleFileChange}/>
                      </label>
                      <p className="pl-1">ó arrastrelo</p>
                    </div>
                      <p className="text-xs leading-5 text-gray-600">El archivo debe ser menor a {size_cap/1000}GB y en un formato de video o audio</p>                     
                    </div>
                    {
                      <div className={isFileHidden ? "hidden" : ""}>
                        <div className= "mt-4 justify-center items-center">
                          <span className="text-xs">El archivo seleccionado : &nbsp;</span>
                          {Specs.name ? (<p className="text-lg text-blue-800 font-semibold"> {Specs.name}</p> ): ""}
                          {Specs.size ? (<p className="text-xs text-blue-500 font-semibold"> {Specs.size} Mb</p>) : ""}                                             
                          <div>{<Dropdown message={meessageContent} options={optionsContent} selection={selectionContent} onSelect={handleSelectContent}/>}</div>
                          <div>{<Dropdown message={meessageLanguage} options={optionsLanguage} selection={selectionLanguage} onSelect={handleSelectLanguage}/>} </div>                        
                        </div>
                                                  
                        <div className="mt-5 flex justify-center" >
                          <div className={buttonHidden ? "hidden" : ""}>
                            <button className="bg-green-500 rounded-md p-2 px-2.5 text-white" onClick={handlerFormSubmit}>Enviar</button>
                          </div>
                          <XCircleIcon className="ml-5 h-10 w-10 text-rose-600 cursor-pointer" aria-hidden="true" onClick={handlerFormCancel} />
                          
                        </div>
                      
                      </div>                                     
                                        
                    } 
                    <div className={progress.started ? "" : "hidden"}>
                      <div className= "mt-4 flex-1 justify-center items-center">
                        <div>{progress.started && <progress max="100" value={progress.pc} className="color-black"></progress>}</div>
                        <div>{msg && <span className="font-medium">{msg}</span>}</div>
                      </div>
                    </div>

                </div>
            </div>
          </div>          
        </div>
      </div>
    </form>    
  );
}
