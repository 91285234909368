import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import CasosdeUso from "../components/CasosdeUso";
import { LightBulbIcon, RocketLaunchIcon,PencilSquareIcon , BoltIcon} from '@heroicons/react/24/outline'

export default function CreacionContenidoPage() {
  
  const ventajas = [
    { title: 'Mayor Productividad y Enfoque Creativo', description: 'Un proceso de procesamiento más rápido libera tiempo para que los creadores se enfoquen en aspectos más creativos de su trabajo, como la planificación de contenido, la mejora de la calidad visual, la narrativa y la estrategia general. La reducción de tareas tediosas permite una mayor expresión de la creatividad', icon: LightBulbIcon},
    { title: 'Creación Eficiente de Contenido Complementario', description: 'Los creadores pueden aprovechar las transcripciones para crear fácilmente contenido escrito adicional. Pueden transformar rápidamente las ideas expresadas en los videos y podcasts en artículos, blogs u otros formatos escritos, ampliando así su alcance y ofreciendo contenido diversificado', icon: PencilSquareIcon},
    { title: 'Adaptabilidad a las Plataformas en Tiempo Real', description: 'En entornos donde la rapidez es esencial, como las redes sociales, un procesamiento más rápido permite a los creadores adaptarse rápidamente a eventos en tiempo real. Pueden responder a noticias, eventos o tendencias actuales de manera más ágil, lo que puede aumentar la relevancia y la visibilidad del contenido', icon: RocketLaunchIcon },    
    { title: 'Competitividad en el Mercado', description: 'La capacidad de entregar contenido de manera más rápida puede ser un factor competitivo en el mercado. Los creadores que pueden mantenerse ágiles y responder rápidamente a las demandas del público a menudo tienen una ventaja en términos de relevancia y visibilidad', icon: BoltIcon },
  ]

  return (
    <div className="bg-slate-50">    
        <HeaderLanding/>
         {/* use standard component to render this page content in casos de uso format */}
        <CasosdeUso ventajas={ventajas}/>
        <Footer/>   
     </div>
  );
};
