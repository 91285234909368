import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import CasosdeUso from "../components/CasosdeUso";
import { CurrencyDollarIcon, RectangleStackIcon,LanguageIcon , UserGroupIcon} from '@heroicons/react/24/outline'


export default function ActasReunionesPage() {

  const ventajas = [
    { title: 'Ahorro en recursos', description: 'Elimina la necesidad de transcribir manualmente las discusiones y decisiones de la reunión. La aplicación automatiza el proceso, permitiendo a los usuarios generar actas de manera rápida y eficiente', icon: CurrencyDollarIcon },
    { title: 'Acceso Rápido a la Información', description: 'Facilita el acceso a la información clave en tiempo real. Los usuarios pueden buscar y encontrar fácilmente detalles específicos, evitando la revisión tediosa de largas grabaciones ó notas para soportar la toma de decisiones', icon: RectangleStackIcon },
    { title: 'Consistencia y Precisión', description: 'Garantiza la consistencia y precisión en la documentación. La aplicación utiliza algoritmos avanzados para transcribir de manera confiable el contenido de la reunión, evitando errores humanos y malentendidos', icon: LanguageIcon},
    { title: 'Colaboración Mejorada', description: 'Mejora la colaboración entre los participantes al proporcionar actas claras y comprensibles. Al tener un registro preciso de lo discutido, los equipos pueden trabajar de manera más efectiva y estar alineados en los objetivos y decisiones tomadas durante la reunión', icon: UserGroupIcon },
  ]

  return (
    <div className="bg-slate-50">    
        <HeaderLanding/>
        {/* use standard component to render this page content in casos de uso format */}
        <CasosdeUso ventajas={ventajas}/>
        <Footer/>   
     </div>
  );
};

