
import { useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import AuthContext from "./AuthContext";


export default function PrivateRoutes(){    
    const {User}= useContext(AuthContext);
    console.log("private router access user data " +" " + User);
    console.log(User);

    return(
        User ? <Outlet/> : <Navigate to="/login"/>
    );
}