import logo from "../images/logo.svg";
import { Link } from "react-router-dom";

const products = [
    { name: 'Actas de reuniones', href: '/casos-de-uso-actas'},
    { name: 'Podcast y Videos', href: '/casos-de-uso-podcastvideos'},
    { name: 'Documentación de capacitaciones', href: '/casos-de-uso-documentacioncapacitaciones'},  
    { name: 'Creación de contenido', href: '/casos-de-uso-creaciondecontenido'},
  ]

function Footer() {

    const year = new Date().getFullYear();

    return(
                   
        <footer className="bg-white text-center border-t-2 text-neutral-600 lg:text-left px-4 lg:px-1">
            <div className="mx-auto flex-1 max-w-7xl items-center justify-between py-6" >
                <div className="grid-1 grid gap-8 md:grid-cols-3 lg:grid-cols-4">
                
                    <div className="">       
                        <div className="mb-4 flex justify-center md:justify-start items-center font-semibold">                    
                            <Link to="/" className="-my-1.5 py-1.5" onClick= {() => window.scrollTo(0, 0)}>
                                <div className="flex">
                                    <img className="h-8 w-auto" src={logo} alt="logo"/>
                                    <span className="ml-1.5 text-xl font-bold">Transcribe-AI</span>
                                </div>
                            </Link>                        
                        </div>                  
                    </div>
                    

                <div className="">
                    <h6 className="mb-4 flex-1 font-semibold">Casos de Uso</h6>
                    {products.map((item) => (                        
                        <p className="mb-4" key={item.name}>
                        <Link to={item.href} className="text-neutral-600 dark:text-neutral-200" onClick= {() => window.scrollTo(0, 0)}>{item.name}</Link>
                        </p> 


                    ))}
                </div>

                <div className="">                        
                        <Link to="/seguridad" className="text-neutral-600 dark:text-neutral-200" onClick= {() => window.scrollTo(0, 0)}>
                            <h6 className="mb-4 flex justify-center font-semibold md:justify-center">Seguridad </h6>
                        </Link>
                </div>
                
                                
                <div>
                    <h6 className="mb-4 flex items-center justify-center font-semibold md:justify-end">Contacto</h6>                        
                    <p className="mb-4 flex items-center justify-center md:justify-end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="mr-3 h-5 w-5">
                            <path
                            d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                            <path
                            d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                        </svg>
                        info@transcribe-ai.com
                    </p>   
                </div>

            </div>   
            </div>            
            <div className="bg-white mb-10 text-center text-xs">
                <span>© Copyright {year} Transcribe AI</span>                    
            </div>
        </footer>
        
    );

}

export default Footer;


