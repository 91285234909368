import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import CasosdeUso from "../components/CasosdeUso";
import { PuzzlePieceIcon, PaintBrushIcon,VideoCameraIcon , UserPlusIcon} from '@heroicons/react/24/outline'

export default function PodcastVideosPage() {
  
  const ventajas = [
    { title: 'Identificación Rápida de Contenido Relevante', description: 'Los resúmenes permiten a los usuarios identificar rápidamente el contenido más relevante de un video o podcast, util para  audiencias que buscan información específica y rápida', icon: PuzzlePieceIcon},
    { title: 'Facilita la Creación de Contenido Multimedia', description: 'Al tener transcripciones y resúmenes disponibles, los creadores pueden fácilmente generar infografías o crear contenido multimedia adicional. Esto enriquece la presentación del contenido y atrae a diferentes tipos de audiencias', icon: PaintBrushIcon },
    { title: 'Seguimiento de Temas y Tendencias', description: 'La aplicación facilita el seguimiento de temas y tendencias recurrentes en los contenidos. Los creadores pueden analizar las transcripciones para identificar patrones, temas populares o áreas que requieren mayor atención, lo que contribuye a una estrategia de contenido más informada', icon: VideoCameraIcon},
    { title: 'Feedback y Mejora Continua', description: 'Al revisar transcripciones y resúmenes, los creadores pueden obtener feedback más detallado sobre su propio desempeño. Esto facilita la identificación de áreas de mejora y la adaptación de estrategias para satisfacer las expectativas y necesidades de la audiencia', icon: UserPlusIcon },
  ]

  return (
    <div className="bg-slate-50">    
        <HeaderLanding/>
         {/* use standard component to render this page content in casos de uso format */}
        <CasosdeUso ventajas={ventajas}/>
        <Footer/>   
     </div>
  );
};
