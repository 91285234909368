
import talking from "../images/talking.jpg"

import { CloudIcon, ClockIcon, LockClosedIcon } from '@heroicons/react/20/solid'


function Content() {
  
  const features = [
    {
      name: 'Información en la nube.',
      description:
        'Aproveche la eficacia y la confiabilidad de la infraestructura completamente en la nube, lo que significa que puede acceder a sus datos y trabajar en sus proyectos desde cualquier lugar.',
      icon: CloudIcon,
    },
    {
      name: 'Protección de datos',
      description: 'Empleamos políticas y medidas de seguridad estrictas, asegurando que sus datos estén a salvo de acceso no autorizado, modificación o divulgación',
      icon: LockClosedIcon,
    },    
    {
      name: 'Libere su tiempo.',
      description: 'Con nuestra capacidad de transcripción automática, ya no necesita pasar horas escribiendo o analizando el contenido de videos.',
      icon: ClockIcon,
    },

  ]

  return (
    <div className="bg-slate-100">
      <div className="overflow-hidden py-2 pb-6 pt-10 sm:pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 items-center gap-y-10 sm:gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-xl">                
                <p className="mt-2 text-4xl text-center font-bold tracking-tight text-gray-900 sm:text-3xl lg:text-center">Ahorre tiempo y esfuerzo sin comprometer su privacidad</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                No tiene que preocuparse por tomar notas. Concéntrese en el contenido y deje que nosotros nos encarguemos, capturando cada detalle por usted.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <img
                src={talking}
                alt="Product screenshot"
                className="max-w-xs md:max-w-md rounded-md shadow-xl ring-1 ring-gray-400/10 "             
              />
            </div>
          </div>
        </div> 
      </div>          
    </div>
  );
}

export default Content;
