import HeaderLanding from "../components/HeaderLanding";
import Seguridad from "../components/Seguridad";
import Footer from "../components/Footer";

export default function SecurityPage() {
  return (
    <div className="bg-slate-50">    
        <HeaderLanding/>
        <Seguridad/> 
        <Footer/>            
     </div>
  );
};