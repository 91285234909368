import logo from "../images/logo.svg";
import { EyeIcon, EyeSlashIcon} from '@heroicons/react/20/solid'
import { useState,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import AuthContext from "./AuthContext";
import { GoogleOAuthProvider, GoogleLogin} from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";



const poolData = {
  UserPoolId: 'us-east-1_3d8rIHxWV',
  ClientId: '4a5q47b8mjekdd8n2og8fhjp65',
}; 
var userPool = new CognitoUserPool(poolData);


export default function Login() {
    
  
    const {setUser}= useContext(AuthContext);
    const navigate = useNavigate();
       

    const [Email, setEmail] = useState("")
    const [Password, setPassword] = useState("")

    const [ViewPassword,setviewPassword]=useState(false);
    const [ViewPasswordIcon,setviewPasswordIcon]=useState(EyeSlashIcon);
   

    const onChangeEmail=(event)=>{
      setEmail(event.target.value);
    }

    const onChangePassword=(event)=>{
      const newPassword=event.target.value;      
      setPassword(newPassword);            
    }       

    const handleViewPassword =()=>{
      setviewPassword(!ViewPassword);
      if (ViewPassword){setviewPasswordIcon(EyeSlashIcon)}
      else{setviewPasswordIcon(EyeIcon)}      
    }

    const handleSubmitLogin = event => {
      event.preventDefault();

      const userData = {
          Username: Email,
          Pool: userPool,
      };

      const authDetails = new AuthenticationDetails({
        Username: Email,
        Password,
      });
    
      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authDetails, {
        
        onSuccess: function (result) {
            //console.log('Inicio de sesión exitoso', result.idToken.payload);  
            const username=result.idToken.payload;
            setUser(username);  // Save data to context
            navigate("/dashboard");  // redirect user to /dashboard
            
        },

        onFailure: function(err) {
            console.log('Inicio de sesión fallido', err);
            alert("La informacion ingresada no es correcta");
        },
      });
    }    

    return (
      <>        
        <div className="flex mx-auto min-w-max min-h-max flex-1 flex-col justify-center px-12 py-10 sm:py-10 md:pb-20 lg:px-12 bg-white/80">
          <div className="sm:w-full md:max-w-md sm:max-w-sm">                   
            <div className="flex justify-center visible md:invisible">
              <Link to="/">              
                <div className="mt-4 flex items-center border-gray-500 ">
                    <img
                      className="h-8 w-auto"
                      src={logo}
                      alt="Transcribe AI"
                    />
                    <div className='ml-1 lg:mx-2 font-semibold text-xl pr-4 '>Transcribe-AI</div>
                </div>
              </Link>
            </div> 
            <div className='flex font-bold text-2xl justify-center invisible md:visible'>Bienvenido!</div>                   
          </div>        
  
          <div className="md:mt-10 flex-row justify-center sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmitLogin}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={Email}
                    autoComplete="email"
                    required
                    autoFocus
                    className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={onChangeEmail}
                  />
                </div>
              </div>
  
              <div>
                <div className="items-center">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Contraseña
                  </label>                  
                </div>
                
                <div className="mt-2 flex justify-between items-center bg-white rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:text-sm sm:leading-6">
                  <input
                    id="password"
                    name="password"
                    type={ViewPassword ? "text":"password"}
                    value={Password}
                    autoComplete="current-password"
                    required
                    className="ml-1.5 block w-full placeholder:text-gray-400 outline-none"
                    onChange={onChangePassword}                    
                  />
                  <div className="mr-1.5 bg-white">
                    <ViewPasswordIcon className="h-5 w-5 hover:cursor-pointer bg-white text-gray-500" onClick={handleViewPassword}/>                 
                  </div>
                </div>                                          
                <div className="flex m-1 text-xs">
                    <Link to="/recuperarconstraseña" className="font-semibold text-slate-400 hover:text-indigo-500">
                      Olvidaste la contraseña?
                    </Link>
                </div>               
                
              </div>
  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Ingresar
                </button>
              </div>
            </form>
  
            <p className="mt-1 text-center text-sm text-gray-500">
              No tienes una cuenta?{' '}
              <Link to="/registrarse" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                Registrarse
              </Link>
            </p>

            <div className="mt-10 flex justify-center drop-shadow-2xl">                

                <GoogleOAuthProvider clientId="768779695063-f5ulgns21628qsahora97dth76r07m27.apps.googleusercontent.com">

                  <GoogleLogin                    
                    text="signup_with"                                             
                    theme="outline"
                    shape="pill"                    
                    //className="mt-10 flex w-full justify-center align-middle rounded-md text-black px-3 py-1.5 text-sm leading-6 shadow-md bg-white hover:bg-slate-200"           
                    onSuccess={credentialResponse => {
                      console.log(credentialResponse);
                      const decoded = jwtDecode(credentialResponse.credential);
                      console.log(decoded);
                      const username={name:decoded.given_name, email:decoded.email};                      
                      setUser(username);  // Save data to context                 
                      navigate("/dashboard");

                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />                

                </GoogleOAuthProvider>


              </div>
          </div>
        </div>
      </>
    )
  }
  