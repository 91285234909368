import { Outlet } from "react-router-dom";
import Header from "./Header";

import AuthContext from "./AuthContext";
import VideoContext from "./VideoContext";
import { useEffect, useContext } from "react";


export default function LayoutDashboard () {        
    console.log("layout print")

    const { User } = useContext(AuthContext);
    const user = {
      name: User.name,
      email: User.email,
    }

    const {setVideosFetched, setLoading, VideoAdded}=useContext(VideoContext);

    
    //Videos are fetched in this parent component and stored in VideosFetched (Context variable) to be availabe fro use in all components and in particular in VideoList
    const getVideoList = async (event) => {
      // event.preventDefault();    
      console.log("user.email:" + User.email);
      console.log(typeof User.email);    
      fetch(`https://yqxdpx5bo8.execute-api.us-east-1.amazonaws.com/dev/data?email=${user.email}`,{                
        mode:"cors",
        method:"GET",                                            
      })
      .then(res => {
        if (!res.ok) {
          throw new Error('Network response was not ok ' + res.statusText);
        }
        return res.json(); // This returns a promise
      })
      .then(data => {
        // console.log("data:", data);
        setVideosFetched(data.media_info); // set the media_info array
        // setcurrentVideos(data.qty); // set the curent qty of videos
        setLoading(false);
      })
      .catch(err => {
        console.error("Error fetching data: ", err);
      });
    }
  
    console.log("VideoAdded" + VideoAdded);
  
    //Use effect fetch all videos and its updated whenever a VideoAdded context variables is changed in Upload component when a video is succesfully uploaded
    useEffect(() => {        
      getVideoList();
    },[VideoAdded]);

    const year = new Date().getFullYear();
    
    return(
        <>
        <Header/>
        <Outlet/> 
        <div className="pt-4 bg-white mb-10 text-center text-xs border-t border-gray-200  bottom-0 left-0 right-0 ">
          <span>© Copyright {year} Transcribe AI</span>                    
        </div>          
        </>

    );

}