import HomePage from "./pages/HomePage"
import ActasReunionesPage from "./pages/ActasReunionesPage";
import PodcastVideosPage from "./pages/PodcastVideosPage";
import CapacitacionPage from "./pages/CapacitacionPage";
import CreacionContenidoPage from "./pages/CreacionContenidoPage";
import SecurityPage from "./pages/SecurityPage";
import LoginPage from "./pages/LoginPage";

import RegistrationPage from "./pages/RegistrationPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { useState } from "react";
import PrivateRoutes from "./components/PrivateRoutes";

import AuthContext from "./components/AuthContext";
import VideoContext from "./components/VideoContext";

import LayoutDashboard from "./components/LayoutDashboard";
import VideoList from "./components/VideoList";
import Upload from "./components/Upload";
import Youtube from "./components/Youtube";
import VideoContainer from "./components/VideoContainer";

function App() {

  const [User, setUser] = useState(null);  // Use context to saved the info of the logged user. Changeable in Login, acceseable in PrivateRouter and Header component

  const[VideosFetched, setVideosFetched]=useState([]); 
  const[Loading, setLoading]=useState(true);
  const[VideoAdded, setVideoAdded]=useState([]);
  

  return (
    
    // context variables to be share across all components
    <AuthContext.Provider value={{ User, setUser}}>  
      <VideoContext.Provider value={{ VideosFetched, setVideosFetched, Loading, setLoading, VideoAdded, setVideoAdded}}>  
        <BrowserRouter>      
          <Routes>           
              <Route path="/" element={<HomePage/>}/>
              <Route path="/seguridad" element={<SecurityPage/>}/>
              <Route path="/casos-de-uso-actas" element={<ActasReunionesPage/>}/>  
              <Route path="/casos-de-uso-podcastvideos" element={<PodcastVideosPage/>}/>  
              <Route path="/casos-de-uso-documentacioncapacitaciones" element={<CapacitacionPage/>}/>  
              <Route path="/casos-de-uso-creaciondecontenido" element={<CreacionContenidoPage/>}/>  
              <Route path="/login" element={<LoginPage/>}/>
              <Route path="/registrarse" element={<RegistrationPage/>}/>  
              <Route path="/recuperarconstraseña" element={<ForgottenPasswordPage/>}/>    

              {/* PrivateRouter conditional render all children components using outlet component defined in react-router v6 */}
              <Route element={<PrivateRoutes/>}>
                {/* Use LayoutDashboard component to render child components; using outlet property */}
                <Route element={<LayoutDashboard/>}>
                  <Route path="/dashboard" element={<VideoList/>}/>
                  <Route path="/agregar" element={
                    // flex container to add all ways to add video to dashboard
                    <div className="flex-1">                      
                      
                      <Upload/> {/* Upload component to upload video by selecting or dragging from user pc */}

                    </div>
                  }/>
                  <Route path="/:name" element={<VideoContainer/>}/>               
                </Route>
              </Route>   
            
          </Routes>
        </BrowserRouter>
      </VideoContext.Provider>
    </AuthContext.Provider>
      
    
  );
}

export default App;


