import { CloudArrowUpIcon, LockClosedIcon, ArrowPathIcon, FingerPrintIcon} from '@heroicons/react/20/solid'

function Seguridad() {
  
  const features = [
    {
      name: 'Amazon Web Services (AWS).',
      description:
        'Todo nuestro software se aloja en las instalaciones de AWS en EE. UU. , proporcionando protocolos robustos en cuanto a garantías regulatorias y de cumplimiento, incluidas SOC 1-3 e ISO 27001',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Protección de Datos.',
      description: 'Nos comprometemos con la protección y privacidad de sus datos personales. Empleamos políticas y medidas de seguridad estrictas, asegurando que sus datos estén a salvo de acceso no autorizado, modificación o divulgación',
      icon: LockClosedIcon,
    },    
    {
      name: 'Encriptación de datos.',
      description:
        'Todas las conexiones están cifradas mediante SSL. Todos los datos entre el cliente y el servidor y entre los servicios Backend se cifran en tránsito mediante HTTPS',
      icon: ArrowPathIcon,
    },
    {
      name: 'Autenticación.',
      description:
        'Hemos implementado un sólido sistema de registro e inicio de sesión basado en la autenticación Oauth 2.0 de AWS. Este avanzado método de autenticación nos permite ofrecer un proceso de sin la necesidad de almacenar datos sensibles, como contraseñas, en nuestra propia base de datos. En lugar de eso, confiamos en la robusta infraestructura de seguridad uno de los proveedores de servicios en la nube más confiables del mundo',
      icon: FingerPrintIcon,
    }

  ]

  const politica=`1. INTRODUCCIÓN: Transcribe AI (en adelante, “nosotros” o “la Compañía”) tiene en cuenta la importancia del manejo seguro y confidencial de los datos personales de los usuarios de nuestra plataforma (en adelante, el "Usuario" o "Usted"). Esta política de protección de datos (en adelante, la "Política") establece las pautas sobre cómo recogemos, usamos, revelamos y gestionamos sus datos personales, cumpliendo con la legislación colombiana establecida en la Ley 1581 de 2012 y el Decreto 1377 de 2013.
  
  2. RECOPILACIÓN Y USO DE DATOS: Transcribe AI recoge y procesa datos personales necesarios para la provisión de sus servicios. Los datos que tratamos incluyen los archivos de audio y/o video que usted carga y el contenido que se deriva de ellos. La Compaña utilizará esta información para el fin exclusivo de proporcionar el servicio requerido, sin compartirlos con terceros.
  
  3. CONTENIDO DE LOS USUARIOS: Nosotros almacenaremos los archivos de audio/video cargados y el contenido que se deriva de ellos por un tiempo limitado. Después de este período, los archivos son eliminados permanentemente de nuestras bases de datos y servidores, garantizando así la privacidad y confidencialidad de su información. 

  4. TERCEROS: Transcribe AI no venderá, alquilará ni divulgará a terceros los datos personales del usuario sin su consentimiento previo, a menos que así lo exija la ley. En todos los demás casos, solicitaremos su consentimiento explícito antes de compartir sus datos personales. 
  
  5. SEGURIDAD DE LOS DATOS: Todos los datos que manejamos son almacenados con medidas técnicas y organizativas para garantizar su seguridad. Utilizamos protocolos de seguridad estándar para proteger la información, incluyendo la encriptación de datos y herramientas de seguridad de TI para proteger contra el acceso no autorizado a nuestros sistemas.
  
  6. DERECHOS DE LOS USUARIOS: De acuerdo con la Ley 1581 de 2012 y el Decreto 1377 de 2013, los usuarios de Transcribe AI tienen derecho a acceder, rectificar, cancelar y oponerse al tratamiento de sus datos personales. Para ejercer estos derechos, los usuarios pueden contactarnos a través de la dirección de correo electrónico proporcionada en nuestro sitio web. 
  
  7. CAMBIOS EN LA POLÍTICA DE PROTECCIÓN DE DATOS: Transcribe AI se reserva el derecho a modificar esta Política en cualquier momento. Publicaremos cualquier cambio en nuestra Política de Protección de Datos en esta página y, si son significativos, proporcionaremos un aviso más prominente.
  
  8. CONSENTIMIENTO: Al usar los servicios de Transcribe AI, usted acepta y consiente la recopilación, uso y divulgación de sus datos personales de la manera descrita en esta Política.
  
  Para cualquier pregunta o preocupación relacionada con la Política de Protección de Datos, por favor contáctenos a la dirección de correo electrónico proporcionada en nuestro sitio web.`

  return (
    <div className="pt-24">
      <div className="bg-white sm:py-3">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl text-center">            
            <p className="mt-1 text-3x1 font-bold tracking-tight text-gray-900 sm:text-4xl">
              Seguridad
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Reconocemos que cuando nos eliges, nos confías no solo tu contenido sino también tu información personal y la de tus Stakeholders. Para proteger estos datos sensibles, tenemos una serie de rigurosas medidas de protección de datos, respaldadas por tecnología de punta y políticas estrictas, garantizando que la privacidad y seguridad de tus datos sean respetadas en todo momento.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-5xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-cyan-600">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}.</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mx-auto max-w-3xl text-center">            
            <p className="mt-14 text-3x1 font-bold tracking-tight text-gray-900 sm:text-3xl">
              Política de Protección y Tratamiento de Datos
            </p>
            <p className="mt-6 mb-10 text-xs text-gray-600 text-justify" style={{ whiteSpace: 'pre-line' }}>
              {politica}
            </p>
          </div>
        </div>
      </div>             
    </div>
  );
}

export default Seguridad;
