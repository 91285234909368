import { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'



export default function Dropdown({ message, options, selection, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    // CLOSE DROPDOWN
    setIsOpen(false);
    // WHAT OPTION DID THE USER CLICK ON???
    onSelect(option);   
  };

  const renderedOptions = options.map((option,index) => {
    return (    
        <div onClick={() => handleOptionClick(option)} key={index}>
          <div className='inline-flex justify-center gap-x-1.5 px-5 py-2 text-sm font-semibold text-gray-900 hover:drop-shadow-2xl hover:bg-gray-100 hover:cursor-pointer hover:ring-2 hover:ring-blue-300'>
              {<img src={option.src} alt={option.alt}/>}
              {option.label}
          </div>                 
      </div>
    );
  });

  let content = message;
  let flag="";
  if (selection) {
    content = selection
  }

  return (
    <div>
      <div className="mt-4 relative inline-block text-left" onClick={handleClick}>
          <div className='inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:cursor-pointer'>        
            {content}                                 
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      {isOpen && <div>{renderedOptions}</div>}
    </div>
  );
}
