import { useState, useEffect } from 'react';
import { ClipboardDocumentCheckIcon, CursorArrowRaysIcon, SunIcon } from '@heroicons/react/20/solid'
import {useLocation} from 'react-router-dom';
import headphones from "../images/headphones.jpg";


export default function VideoContainer() {
    
    const location = useLocation()
    //get the user email prop to render
    const email = location.state.email    
    //get the video_key prop to render
    const video_key = location.state.video_key
    //get the video_url prop to render
    const video_url = location.state.video_url  
    //get the media_content prop to render
    const media_content = location.state.media_content 
    //get the media_language prop to render
    const media_language = location.state.media_language 
    //get the transcription_key prop to render
    const transcription_key=location.state.transcription_key 
    //get the transcription_url prop to render
    const transcription_url=location.state.transcription_url
    //get the duration prop of the video
    const duration=location.state.media_lenght_min
    //get the summary prop of the video
    const summary_url=location.state.summary_url
       
   
    const [VideoUrl, setVideoUrl] = useState("");
    const [Transcription, setTranscription] = useState("");
    const [SummaryTemp, setSumaryTemp] = useState("");
    const [Summary, setSumary] = useState("");
    const [LoadingSummary, setLoadingSummary]=useState(false);
    const [HiddenSummarization, setHidenSumarization] = useState(true);
    const [Duration,setDuration]= useState("");
    const [isHiddenCopyTranscription, setisHiddenCopyTranscription] = useState(false);
    const [isHiddenCopySumarization, setisHiddenCopySumarization] = useState(false);
        
    const fetchDataTranscription = async () => {    
      console.log("start fetchDataTranscription ");
      const response = await fetch(transcription_url);
      const data = await response.text();  
      setTranscription(data);  
      console.log("transcription lenght :" + Transcription.length );
                 
    } 

    const fetchDataSummaryTemp = async () => {    
      const response = await fetch(summary_url);
      const data = await response.text();  
      setSumaryTemp(data); 
      //console.log("SummaryTemp" +SummaryTemp);
    
      if (SummaryTemp.includes('<Error><Code>NoSuchKey</Code>')){        
        setHidenSumarization(true);       
      }
      else{
        setSumary(SummaryTemp);
        setHidenSumarization(false);
        
      }
    }
    
    useEffect(()=>{
      setVideoUrl(video_url);
      setDuration(duration);
      fetchDataTranscription(); 
      fetchDataSummaryTemp(); 
           
    },[video_url, Transcription, HiddenSummarization]);


    // function to allow user to copy transcript text into clipboard
    const handleCopyClickTranscripcion=()=>{
      navigator.clipboard.writeText(Transcription);
      const timeout = setTimeout(() => {
        setisHiddenCopyTranscription(false);
      }, 1000)
      setisHiddenCopyTranscription(true);     

    };

    // function to allow user to copy summary text into clipboard
    const handleCopyClicksSummarization=()=>{
      navigator.clipboard.writeText(Summary);
      const timeout = setTimeout(() => {
        setisHiddenCopySumarization(false);
      }, 1000)
      setisHiddenCopySumarization(true);     

    };

    // generate summary wher user press the generate summary button
    const handleClickGenerateSummarization=()=>{
      console.log("Transcription.length :"+Transcription.length);
      console.log("Transcription :"+Transcription);
      // 2500 characters with spaces is defined as minumun transcription content to generate a summary
      if (Transcription.length>2500){
        setLoadingSummary(true); 
        //query is directly to summarization lambda endpoint. No Apigateway; beacuse timeout is only 29sec.
         fetch("https://bheifjoi55jwha54lt6kw6fk3e0etwmx.lambda-url.us-east-1.on.aws/",{                
          mode:"cors",
          method:'post',                                       
          body:JSON.stringify({
            "email":email,
            "video_key":video_key,
            "transcription_key":transcription_key,
            "media_content":media_content,
            "media_language":media_language
          }),      
          })
          .then(response=> {
              console.log(response);
              return response.json();
          })
          .then(data => {
            console.log("summary lambda response");      
            // url:
            console.log(data);
            // const parsedBody = JSON.parse(data); 
            // console.log("parsedBody :" +parsedBody); 
            const presigned_url_summary=data.presigned_url_summary; // This is your S3 presigned URL
            console.log("presigned_url_summary: " + presigned_url_summary);
  
            const summary=data.summary;
  
            setSumary(summary);           
            setHidenSumarization(false);        
          })
          .catch(error => {
            // Handle fetch errors or any thrown errors from the .then() block
            console.error('There has been a problem with your fetch operation:', error);
            alert('No se pudo procesar el resumen, por favor intente nuevamente');
            // Here we reset the loading state since the operation failed
            setLoadingSummary(false);
          });
      }
      else{
        alert("No hay sufiente contenido para generar un resumen");
      }      

    }


    return (
    <>
      <div className="grid justify-center space-y-4 border-b border-gray-100">          
            <h1 className='text-xs text-cyan-700 font-bold leading-loose text-center border-spacing-2 sm:text-xl truncate sm:text-ellipsis'>{video_key}</h1>          
            <h2 className='text-xs text-slate-500 font-semibold text-center border-b border-gray-200 sm:text-xl truncate sm:text-ellipsis '>{media_content}</h2>
      </div>
      
      <div className="mt-6 relative isolate overflow-hidden bg-white px-6 py-2 sm:py-2 lg:overflow-visible lg:px-0">       
        <div className="mx-auto grid justify-center max-w-2xl grid-cols-1 gap-x-8 gap-y-1 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-1 ">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">        
          </div>
          <div className="p-10 flex justify-center lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1">
              <div className="rounded-md border-1 overflow-hidden shadow-2xl">                                
                  <video                    
                      src={VideoUrl+"#t=5"}
                      controls width={500}
                      width="max-w-full"
                      type="video/*,audio/*"  
                      autoPlay={false} 
                      muted={false}                                       
                      title="video"
                      poster={headphones}
                  />                              
              </div>
          </div>

          {/* Transcripcion block */}
          <div className="justify-items-center lg:col-span-1 lg:col-start-1 lg:row-start-1 lg:min-w-fit lg:max-w-full lg:grid-cols-1 lg:gap-x-8 lg:p-6 ">          
            <div className="flex justify-center">          
              <div className="max-w-xs sm:max-w-fit md:max-w-fit lg:max-w-fit lg:min-w-lg text-md leading-7 text-gray-700 ">
                  <div className="flex justify-between items-center lg:min-w-lg">
                      <p className="text-xl font-bold leading-7 text-indigo-700">Transcripción</p>                    
                      <div className='flex'>                      
                        <ClipboardDocumentCheckIcon className="h-6 w-6 text-slate-400 hover:text-slate-600 hover:cursor-pointer" aria-hidden="true" onClick={handleCopyClickTranscripcion} />
                        <div className={isHiddenCopyTranscription ? "" : "hidden"}>
                          <p className="p-1.5 bg-slate-800 text-xs text-white rounded-lg">Copiado!</p> 
                        </div>                   
                      </div>
                  </div>
                  {Transcription.includes('<Error>') 
                  ? 
                  (
                  <p className='mt-8 text-base text-green-800 font-bold animate-pulse'>Se esta generando la Transcripcion, tomara aproximadamente {Duration} ... </p>
                  ) 
                  :
                  (
                    <textarea cols={70} rows={10} className='mt-4 p-2 resize-none max-w-full border-solid border overflow-x-hidden' readOnly value={Transcription}/>
                  )}
              </div>
            </div>          
          </div>               
        </div>

      {/* summary block */}

      <div className="justify-items-center ">
        {HiddenSummarization ?
        (
          <div className="mt-4 md:mt-6 flex justify-center bg-slate-50">
            {LoadingSummary ?            
            (
              
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"                
                >
                <div className="flex items-center bg-white border-gray-500 border rounded-md p-4 animate-bounce cursor-default">
                  <SunIcon className="h-6 w-auto animate-ping text-green-900" alt="sun icon"/>
                  <div className='ml-4 font-bold text-l pr-6'>Generando resumen...</div>                                    
                </div>
              </button>
            )
            :
            (
              
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={handleClickGenerateSummarization}
                >
                <div className="flex items-center border-gray-500 border rounded-md p-4 hover:text-white hover:bg-green-500">
                  <CursorArrowRaysIcon className="h-6 w-6" alt="Transcribe AI"/>
                  <div className='mx-1.5 font-semibold text-l pr-4'>Generar resumen</div>                  
                </div>
              </button>
            )
            }
          </div>        
        )
        :
        (                
          <div className="mt-4 flex justify-center min-w-fit">          
            <div className="text-md leading-7 text-gray-700 xs:max-w-xs md:w-3/4">
                <div className="flex justify-between items-center max-w-full">
                    <p className="text-xl font-bold leading-7 text-green-700">Resumen</p>                    
                    <div className='flex'>                      
                      <ClipboardDocumentCheckIcon className="h-6 w-6 text-slate-400 hover:text-slate-600 hover:cursor-pointer" aria-hidden="true" onClick={handleCopyClicksSummarization} />
                      <div className={isHiddenCopySumarization ? "" : "hidden"}>
                        <p className="p-1.5 bg-slate-800 text-xs text-white rounded-lg">Copiado!</p> 
                      </div>                   
                    </div>
                </div>
                <textarea cols={70} rows={15} className='mt-4 mb-6 p-2 bg-slate-50 resize-none max-w-xs sm:max-w-full lg:min-w-full border-solid border overflow-x-hidden' readOnly value={Summary}/>     
            </div>
          </div>
        )
        }
        </div>
      </div>      
    </>
  );
}
