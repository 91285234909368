import HeaderLanding from "../components/HeaderLanding";
import Footer from "../components/Footer";
import CasosdeUso from "../components/CasosdeUso";
import { ChatBubbleOvalLeftEllipsisIcon, AcademicCapIcon,BookOpenIcon , UserGroupIcon} from '@heroicons/react/24/outline'


export default function CapacitacionPage() {

  const ventajas = [    
    { title: 'Creación Eficiente de Contenido Complementario', description: 'La aplicación simplifica la creación de contenido de apoyo y memoria para el profesor o expositor. Al contar con transcripciones precisas y estructuradas, pueden fácilmente identificar los puntos clave, elaborar materiales didácticos adicionales y generar recursos que complementen la capacitación. Esto no solo mejora la calidad del material de estudio, sino que también ahorra tiempo al evitar la necesidad de revisar extensas grabaciones o notas manuscritas', icon: BookOpenIcon},
    { title: 'Registro Detallado y Accesible', description: 'La aplicación permite contar con un registro detallado de la información clave presentada durante las capacitaciones. Los usuarios pueden acceder fácilmente a estos registros en cualquier momento, proporcionando un recurso valioso para la revisión y el refuerzo del aprendizaje', icon: ChatBubbleOvalLeftEllipsisIcon },
    { title: 'Facilita la Creación de Materiales de Estudio', description: 'Los usuarios pueden convertir fácilmente la información de las capacitaciones en materiales de estudio estructurados. Esto es especialmente beneficioso para aquellos que desean revisar o compartir los conceptos aprendidos', icon: AcademicCapIcon },    
    { title: 'Colaboración y Compartición Eficientes', description: 'Los usuarios pueden compartir fácilmente sus notas y resúmenes, lo que facilita la colaboración en proyectos y la difusión del conocimiento dentro del equipo', icon: UserGroupIcon },
  ]

  return (
    <div className="bg-slate-50">    
        <HeaderLanding/>
         {/* use standard component to render this page content in casos de uso format */}
        <CasosdeUso ventajas={ventajas}/>
        <Footer/>   
     </div>
  );
};

