import Registration from "../components/Registration";
import logo from "../images/logo.svg";
import { Link } from "react-router-dom";


export default function RegistrationPage() {  

  return (
    <>
    <div className="flex place-content-center min-h-screen relative isolate overflow-hidden bg-gray-900 py-5 md:py-0">
      <img
        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center contrast-50 z-1 "
      />
      <div
        className="hidden sm:absolute sm:top-0 sm:left-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl  "
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] mix-blend-multiply bg-slate-500"          
        />
      </div>     

      <div className="grid grid-cols-1 gap-14 grid-rows-1 justify-items-end items-center md:grid-cols-2 ">       
        <div className="">
            <div className="rounded-lg border-1 overflow-hidden shadow-2xl border-2 border-slate-800">     
              <Registration/>                                                             
            </div>
        </div>        
        <div className="invisible md:visible"> 
          <Link to="/">
            <div className="flex items-center">
              <img
                className="h-8 w-auto md:h-20 drop-shadow-2xl"
                src={logo}
                alt="Transcribe AI"
              />
              <div className='mx-2 font-semibold text-3xl pr-4 text-white tracking-wide'>Transcribe-AI</div>            
            </div> 
          </Link>        
        </div>      
      </div>        
    </div>        
    </>
    );
}
  