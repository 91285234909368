import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, UserCircleIcon, HomeIcon, PlusCircleIcon  } from '@heroicons/react/24/outline';
import logo from "../images/logo.svg";
import { useContext } from 'react';
import AuthContext from "./AuthContext";
import { Link, NavLink, useNavigate} from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

export default function Header(){
  
    const {User}= useContext(AuthContext);
    const navigate = useNavigate();
    
    const user = {
        name: User.name,
        email: User.email,
      }
    const navigation = [
        { name: 'Inicio', icon: HomeIcon, href: '/dashboard'},
        { name: 'Agregar Audio/Video', icon: PlusCircleIcon, href: '/agregar' }   
    ]

    const userNavigation = [  
    { name: 'Cerrar Sesion'}, //Logout does not required to change context state because using <a> element a total refresh is made and User data is erased
    ]
    
    function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
    }

    const handleLogOut=()=>{
      googleLogout();
      navigate("/");     

    }
 
    return (
    <>     
      <div className="min-h-full">
        
        <Disclosure as="nav" className="bg-gray-100">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-6">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex items-center border-gray-500 border-r-2">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Transcribe AI"
                      />
                      <div className='mx-1.5 font-semibold text-l pr-4'>Transcribe AI</div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4 text-gray-300 group-hover:text-white">
                        {navigation.map((item) => (
                          //Navlink allow us to use isActive property, to check if the route is active; and if so we add some style to the className
                          <NavLink
                              key={item.name}
                              to={item.href}
                              className={({isActive})=> isActive ? 'bg-gray-900 text-white rounded-md px-3 py-2 text-sm font-medium' :'text-gray-500 hover:bg-gray-700 rounded-md px-3 py-2 text-sm font-medium'}                              
                              aria-current={({isActive})=> isActive ? 'page' : undefined}
                            >
                            <div className='flex items-center'>
                              <item.icon className="h-6 w-6 mr-2 " aria-hidden="true" />
                              {item.name}
                            </div>
                          </NavLink>                          
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">                   

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex items-center">
                            <span className="sr-only">Open user menu</span>
                            <div className='flex items-center'>
                              <UserCircleIcon className="block h-6 w-6" aria-hidden="true" />
                              <span className="pl-2 bg-gray-100 text-lg text-black font-semibold">{user.name}</span>
                            </div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <button
                                    onClick={handleLogOut}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    //Navlink allow us to use isActive property, to check if the route is active; and if so we add some style to the className
                    <NavLink
                        key={item.name}
                        as="a"
                        to={item.href}
                        className={({isActive})=> isActive ? 'bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium' :'text-gray-500 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium'}                       
                        aria-current={({isActive})=> isActive ? 'page' : undefined}
                      >
                        {item.name}
                    </NavLink>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center">                    
                    <div className="ml-3">
                      <div className='flex items-center'>
                        <UserCircleIcon className="block h-6 w-6" aria-hidden="true" />
                        <span className="pl-2 bg-gray-100 text-lg text-black font-semibold">{user.name}</span>
                      </div>                                    
                    </div>                    
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        onClick={handleLogOut}
                        className="block rounded-md px-5 py-2 text-base font-medium text-gray-700 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <header className="bg-white shadow">
          <div className="mx-auto max-w-9xl px-4 py-2 sm:px-6 lg:px-6">            
          </div>
        </header>        
      </div>
      <div className="border-t border-gray-200"></div>
    </>
  );
}
