import HeaderLanding from "../components/HeaderLanding";
import Hero from "../components/Hero";
import Content from "../components/Content";
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";

export default function HomePage() {
  return (
    <div className="">    
        <HeaderLanding/>
        <Hero/>
        <Content/>
        <Reviews/>
        <Footer/>         
     </div>
  );
};